import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'theme-ui';
import Layout from '@solid-ui-layout/Layout';
import Header from '@solid-ui-blocks/Header/Block01';
import Footer from '@solid-ui-blocks/Footer/Block01';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import styles from './_styles';
import ContactModal from '@solid-ui-blocks/ContactModal/Block01';
import { Helmet } from 'react-helmet';
import NewPricing from '@solid-ui-blocks/Pricing/NewPricing';



const PlanosPage = props => {
    const { allBlockContent } = props.data;
    const content = normalizeBlockContentNodes(allBlockContent?.nodes);

    return (
        <Layout {...props}>
            <Helmet>
                <title>Conheça os planos do JUIT Rimor para Escritórios</title>
                <meta name="title" content="Conheça os planos do JUIT Rimor para Escritórios" />
                <meta
                    name="description"
                    content="Consulte as funcionalidades de cada plano e veja qual se adapta melhor para suas necessidades. Nossos planos atendem escritórios de todos os tamanhos."
                />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://juit.io/planos" />
                <meta property="og:title" content="Conheça os planos do JUIT Rimor para Escritórios" />
                <meta
                    property="og:description"
                    content="Consulte as funcionalidades de cada plano e veja qual se adapta melhor para suas necessidades. Nossos planos atendem escritórios de todos os tamanhos."
                />
                <meta property="og:image" content="https://storage.googleapis.com/juit-io-logos/JUIT-metabg-politica-de-privacidade.png" />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://juit.io/planos" />
                <meta property="twitter:title" content="Conheça os planos do JUIT Rimor para Escritórios" />
                <meta
                    property="twitter:description"
                    content="Consulte as funcionalidades de cada plano e veja qual se adapta melhor para suas necessidades. Nossos planos atendem escritórios de todos os tamanhos."
                />
                <meta property="twitter:image" content="https://storage.googleapis.com/juit-io-logos/JUIT-metabg-politica-de-privacidade.png" />
                <script type='text/javascript'>
                    {`
                    const isBrowser = typeof window !== 'undefined';
                    const _linkedin_partner_id = '4539113';
                    
                    if (isBrowser) {
                        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                        (function (l) {
                            if (!l) {
                                window.lintrk = function (a, b) {
                                    window.lintrk.q.push([a, b]);
                                };
                                window.lintrk.q = [];
                            }
                            var s = document.getElementsByTagName('script')[0];
                            var b = document.createElement('script');
                            b.type = 'text/javascript';
                            b.async = true;
                            b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
                            s.parentNode.insertBefore(b, s);
                        })(window.lintrk);
                    }
                    `}
                </script>
                <noscript>
                    {`<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4539113&fmt=gif" />`}
                </noscript>
            </Helmet>
            <ContactModal />
            <Header content={content['header-light']} menuJustify="space-around" />
            <Container variant="full" sx={styles.heroContainer}>
                <NewPricing />
            </Container>
            <Footer content={content['footer']} />
        </Layout>
    );
};

export const query = graphql`
    query planosPageBlockContent {
        allBlockContent(filter: { page: { in: ["site/planos", "shared"] } }) {
            nodes {
                ...BlockContent
            }
        }
    }
`;

export default PlanosPage;
